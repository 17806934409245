<template>
  <AbstractFilter
    :on-filter="filterList"
    :on-filter-reset="resetFilter"
    :button-required-permissions="requiredPermissions"
    create-new-route-name="topic_new"
    filter-panel-class="filter-panel-topic"
  >
    <Select
      v-model="filter.site"
      :options="sites"
      :label="$t('topic.filter.site')"
      id="filter_site"
    />
    <Select
      v-model="filter.category"
      :options="topicCategoryValues"
      :label="$t('topic.filter.category')"
      id="filter_category"
    />
    <Input
      v-model="filter.title"
      id="filter_title"
      :label="$t('topic.filter.title')"
    />
    <Input
      v-model="filter.slug"
      id="filter_identifier"
      :label="$t('topic.filter.slug')"
    />
    <Input
      v-model="filter.id"
      id="filter_id"
      :label="$t('topic.filter.id')"
    />
  </AbstractFilter>
</template>
<script>
import Input from '@/components/form/inputs/Input'
import Select from '@/components/form/select/Select'
import TopicCategoryMixin from '@/components/mixins/valueObject/TopicCategoryMixin'
import TopicFilterModel from '@/model/TopicFilter'
import AbstractFilter from '@/components/filter/AbstractFilter'
import PermissionService from '@/services/PermissionService'
import { MODULE_MODULES_TOPIC } from '@/model/ValueObject/UserPermissionModules'

export default {
  name: 'TopicFilter',
  mixins: [TopicCategoryMixin],
  data () {
    return {
      filter: this._.cloneDeep(TopicFilterModel),
      requiredPermissions: PermissionService.REQUIRED_PERMISSIONS.TOPIC_PERMISSIONS
    }
  },
  computed: {
    sites () {
      return this.$store.getters['site/enabledSites'](MODULE_MODULES_TOPIC)
    }
  },
  components: {
    AbstractFilter,
    Input,
    Select
  },
  methods: {
    filterList () {
      this.$store.commit('topic/setPage', 1)
      this.$store.commit('topic/setFilter', this.filter)
      this.$store.dispatch('topic/fetch')
      const siteName = this.$store.getters['site/getSiteNameById'](this.filter.site)
      this.$gtm.trackView(this.$route.name, siteName)
    },
    resetFilter () {
      this.filter = this._.cloneDeep(TopicFilterModel)
      this.$store.commit('topic/setPage', 1)
      this.$store.commit('topic/resetFilter')
      this.$store.dispatch('topic/fetch')
      this.$gtm.trackView(this.$route.name)
    }
  },
  created () {
    this.filter = this.$store.getters['topic/filter']
  }
}
</script>

<style lang="scss">
.filter-panel-topic {
  @include bp(7) {
    grid-template-columns: 1fr 1fr;
  }
  @include bp(12) {
    grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
  }
}
</style>
