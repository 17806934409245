<template>
  <section>
    <TopicFilter />
    <Preloader v-if="callingAPI" />
    <TopicTable
      :topics="list"
      :totalCount="totalCount"
      :current-page="page"
      :on-page-change="setPageAndGetRecords"
    />
  </section>
</template>

<script>
import { mapState } from 'vuex'
import Preloader from '@/components/preloader/Preloader'
import TopicFilter from '@/components/topic/TopicFilter'
import TopicTable from '@/components/table/TopicTable'

export default {
  name: 'TopicListView',
  computed: {
    ...mapState([
      'callingAPI'
    ]),
    list () {
      return this.$store.getters['topic/list']
    },
    totalCount () {
      return this.$store.getters['topic/totalCount']
    },
    page () {
      return this.$store.getters['topic/page']
    }
  },
  components: {
    Preloader,
    TopicFilter,
    TopicTable
  },
  methods: {
    getList () {
      this.$store.dispatch('topic/fetch')
        .then(() => {
          this.dataLoaded = true
        })
    },
    setPageAndGetRecords (page) {
      this.$store.commit('topic/setPage', page)
      this.getList()
    }
  },
  mounted () {
    this.getList()
  }
}
</script>
